import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    mode: 'light',
    error: {
      main: '#FF0000',
      contrastText: '#ffffff',
    },
  },
});
