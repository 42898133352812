import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const loginState = (state: RootState) => state.userDetails;

export const userDataSelector = createSelector(loginState, (data) => data.userData);

export const userDetailsSelector = createSelector(loginState, (data) => {
  return {
    userName: data.userData?.idToken?.payload?.name,
    displayName: data.userData?.idToken?.payload?.name,
    userEmail: data.userData?.idToken?.payload?.email,
    userId: data.userData?.idToken?.payload?.sub,
  }
});

