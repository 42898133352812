import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';
import { ICart } from './cart.interface';
import CONSTANTS from '../../constants';
import type { IItem } from '../../../common-utils/interface';

const initialState: ICart = {
  isCartOpen: false,
  cartItems: {},
};

export const cartSlice = createSlice({
  name: 'cartAction',
  initialState,
  reducers: {
    setIsCartOpen: (state: ICart, action: PayloadAction<boolean>) => {
      state.isCartOpen = action.payload;
    },
    addToCart: (state: ICart, action: PayloadAction<{id: string, item: IItem, size: string}>) => {
      const { id, item, size } = action.payload;
      if (state.cartItems[id]) {
        state.cartItems[id].selectedSize[size]++;
      } else {
        state.cartItems[id] = {
          ...item,
          selectedSize: {
            [size]: 1,
          },
        };
      }
    },
    removeFromCart: (state: ICart, action: PayloadAction<{id: string, size: string}>) => {
      const { id, size } = action.payload;
      if (state.cartItems[id]) {
        state.cartItems[id].selectedSize[size]--;
        if (state.cartItems[id].selectedSize[size] === 0) {
          delete state.cartItems[id].selectedSize[size];
        }
        if (Object.keys(state.cartItems[id].selectedSize).length === 0) {
          delete state.cartItems[id];
        }
      }

    },
    clearCart: (state: ICart) => {
      state.cartItems = {};
    },
    removeItemFromCart: (state: ICart, action: PayloadAction<{id: string}>) => {
      const { id } = action.payload;
      delete state.cartItems[id];
    },
  },
});

export const { setIsCartOpen, addToCart, removeFromCart } =
  cartSlice.actions;

export const cartAction = cartSlice.actions;

export default cartSlice.reducer;
