import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
} from 'redux-persist';
// import logger from 'redux-logger';
import rootReducer from './root-reducer';
import { ignoredActions } from './ignoreActions';
import baseApi from '../services/api/baseQuery';

const middleware:any[] = [];

if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger);
}

middleware.push(baseApi.middleware);

const middlewares = (getDefaultMiddleware:any) => getDefaultMiddleware({
  // thunk: false,
  serializableCheck: {
    ignoredActions,
  },
}).concat(middleware);

export const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default { store, persistor };
