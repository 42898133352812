/* eslint-disable no-unused-vars */
import {
  createApi,
  fetchBaseQuery,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../redux/store';

import { BASE_API_URL } from './url.config';
import { statusCode } from '../../constants/constant';
import { setLogoutAction } from '../../redux/login/loginSlice';

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state: RootState = getState() as RootState;
    const token = state?.userDetails?.userData?.idToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
// set refresh token heare
export const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: any,
  extraOptions: {},
) => {
  const result = await baseQuery(args, api, extraOptions);
  if ((
    // @ts-ignore
    result.response
    // @ts-ignore
    && result.response.status
    // @ts-ignore
    && result.response.status === statusCode.unAuthorizedUser)
    || result.error?.status === 'FETCH_ERROR'
    || result.error?.data === 'TypeError: Failed to fetch'
  ) {
    // api.dispatch(setLogoutAction());
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth as typeof baseQuery,
  tagTypes: [
  ],
  endpoints: (builder) => ({}),
});

export default baseApi;