const env = {
  dev: 'dev', test: 'test', stg: 'stg', product: 'product', local: 'local',
};

interface ApiInterface {
  [local:string]:string,
  dev: string,
  stg: string,
  product: string,
}

const API_URL: ApiInterface = {
  local: 'https://api.dev.codewithved.in',
  dev: 'https://api.dev.codewithved.in/',
  stg: 'https://api.stage.codewithved.in/',
  product: 'https://api.codewithved.in/',
};

const S3_BUCKETS: ApiInterface = {
  local: 'dev-documents',
  dev: 'dev-documents',
  stg: 'stage-documents',
  product: 'prod-documents',
};

const url = window.location.origin;

let currentEnv = env.local;
if (url.includes('dev') || url.includes('localhost') || url.includes('192') || url.match(
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
)) {
  currentEnv = env.local;
} else if (url.includes('stage')) {
  currentEnv = env.stg;
} else {
  currentEnv = env.product;
}

const matches = url.match(/([^/.][A-z]+|192)/g) || 'bedbath';
export const match = matches[1] === 'localhost' || matches[1] === '192' ? 'bedbath' : matches[1];
export const BASE_API_URL = API_URL[currentEnv];
export const S3_BUCKET_NAME = S3_BUCKETS[currentEnv];
