/* eslint-disable no-unused-vars */
import { ApiStatusInterface } from '../redux/redux.interface';

export const versionNo = '1.5.9';

export const product = {
  ACTION_PLANNER: 'actionPlanner',
};

export const api = {
  customRole: 'custom:role',
};

export const storage = {
  persistConfigKey: 'OutmarchData',
  localStorageUserData: 'localStorageUserData',
};

export const urls = {
  index: '/',
  home: '/',
  login: '/login',
  insights: {
    insights: '/insights',
    formSubmissions: '/insights/formSubmission',
    ticketsResponse: '/insights/ticketResponse',
  },

  tickets: {
    tickets: '/tickets',
    create: '/tickets/create',
    list: '/tickets/list',
    types: '/tickets/types',
    details: '/tickets/details',
  },
};

export const loadingStatus: Record<ApiStatusInterface, ApiStatusInterface> = {
  idle: 'idle',
  success: 'success',
  loading: 'loading',
  failed: 'failed',
};

export const themeTypes = {
  light: 'light',
  dark: 'dark',
};

export const statusCode = {
  ok: 200,
  badRequest: 400,
  notFound: 404,
  tokenExpired: 0,
  unAuthorizedUser: 401,
  requestTimeout: 502,
  newPasswordRequired: 402,
};

export const graphType = {
  line: 'line',
  bar: 'bar',
  aria: 'aria',
  scatter: 'scatter',
  pie: 'pie',
};

export const graphLables = {
  numberOfResponses: 'Number of Responses',
  notAnswered: 'Not answered',
};

export const questionType = {
  dateAndTime: 'dateAndTime',
  dropdown: 'dropdown',
  geolocation: 'geolocation',
  fileupload: 'fileupload',
  matrix: 'matrix',
  multiplechoice: 'multiplechoice',
  number: 'number',
  ranking: 'ranking',
  rating: 'rating',
  checkboxes: 'checkboxes',
  slider: 'slider',
  multipletextboxes: 'multipletextboxes',
  singletextboxforusercomment: 'singletextboxforusercomment',
};

export const rtk = {
  keepUnusedDataLong: 1800,
  keepUnusedDataMid: 600,
  keepUnusedDataShort: 300,
  keepUnusedDataVeryShort: 30,
};

export const TICKET_STATUS = [
  { value: 'open', label: 'Open' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'resolved', label: 'Resolved' },
  { value: 'closed', label: 'Closed' },
  { value: 'reopen', label: 'Reopen' },
  { value: 'rejected', label: 'Rejected' },
];

export const TICKET_STATUS_LABEL = {
  open: 'Open',
  inprogress: 'In Progress',
  resolved: 'Resolved',
  closed: 'Closed',
  reopen: 'Reopen',
  rejected: 'Rejected',
};

export const PRIORITY = [
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Low',
    value: 'Low',
  },
];

export const TICKETS_VIEW = [
  { key: 'status', label: 'My Open Tickets', apiValue: 'open' },
  { key: 'assignedUserId', label: 'Unassigned Tickets', apiValue: 'null' },
];

export const DATE_TYPE = [
  { key: 'createdAt', label: 'Created' },
  { key: 'lastUpdatedAt', label: 'Last Updated' },
];
