import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusInterface } from '../redux.interface';
import { ILogin } from '../../services/api/authentication/login.interface';
import CONSTANTS from '../../constants';

export interface CustomeLoginInterface {
  userData: ILogin | undefined;
}

const initialState: CustomeLoginInterface = {
  userData: undefined,
};

export const loginSlice = createSlice({
  name: 'loginAction',
  initialState,
  reducers: {
    setLoginActionSucsess: (
      state: CustomeLoginInterface,
      action: PayloadAction<ILogin>
    ) => ({
      userData: action.payload,
    }),
    setLoginActionFailure: (state: CustomeLoginInterface) => ({
      userData: undefined,
    }),
    setLogoutAction: () => initialState,
  },
});

export const { setLoginActionSucsess, setLoginActionFailure, setLogoutAction } =
  loginSlice.actions;

export default loginSlice.reducer;
