import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    mode: 'dark',
    error: {
      main: '#FF6347',
      contrastText: '#ffffff',
      dark: '#FF00',
    },
  },
});
